import { ApiClients } from '@roolz/api/clients'
import {
  AcceptInvitationByHashResponse,
  AcceptInvitationRequest,
  CheckEmailInCompanyRequest,
  Company,
  CreateCompanyRequest,
  DeleteCompanyRequest,
  GetAllPublicCompanies,
  GetAllPublicCompaniesExtended,
  GetAllPublicCompaniesExtendedResponse,
  GetAllPublicCompaniesResponse,
  SendCompanyInvitationRequest,
  UpdateCompanyRequest
} from '@roolz/types/api/companies'
import {
  CompanyInvitationsResponse,
  CompanyMembersResponse,
  GetCompanyMembersRequest,
  Statuses
} from '@roolz/types/api/companyMembers'
import { CompanyInvitation, Profile, Representative } from '@roolz/types/api/profiles'
import { Country } from '@roolz/types/custom'
import { AxiosInstance } from 'axios'
import qs from 'qs'

export class CompaniesApi {
  identity: AxiosInstance

  constructor(clients: ApiClients) {
    this.identity = clients.identity
  }

  sendInvitation(companyId: Company['id'], body: SendCompanyInvitationRequest) {
    return this.identity.post(`/v1/private/companies/id/${companyId}/invitation`, body)
  }

  cancelInvitation(companyId: Company['id'], inviteId: string) {
    return this.identity.post(`/v1/private/companies/id/${companyId}/invitation/id/${inviteId}/cancel`)
  }

  resendInvitation(companyId: Company['id'], inviteId: string) {
    return this.identity.post(`/v1/private/companies/id/${companyId}/invitation/id/${inviteId}/resend`)
  }

  deleteMember(companyId: Company['id'], profileId: Profile['id']) {
    return this.identity.delete(`/v1/private/companies/id/${companyId}/member/${profileId}`)
  }

  acceptInvitationByHash(hash: string, device: any) {
    return this.identity.post<AcceptInvitationByHashResponse>(`/v1/public/companies/invitation/magic_hash/accept`, {
      magic_hash: hash,
      device
    })
  }

  declineInvitationByHash(hash: string, device: any) {
    return this.identity.post<CompanyInvitation>(`/v1/public/companies/invitation/magic_hash/decline`, {
      magic_hash: hash,
      device
    })
  }

  getCompanyMembers(companyId: Company['id'], {
    offset = 0,
    limit = 20,
    ...rest
  }: GetCompanyMembersRequest) {
    return this.identity.get<CompanyMembersResponse>(`/v1/private/companies/id/${companyId}/members`, {
      params: {
        offset,
        limit,
        ...rest
      }
    })
  }

  getCompanyInvitations(companyId: Company['id'], params: GetCompanyMembersRequest) {
    return this.identity.get<CompanyInvitationsResponse>(`/v1/private/companies/id/${companyId}/invitations`, {
      params
    })
  }

  getInvitationsStatuses(companyId: Company['id']) {
    return this.identity.get<Statuses>(`/v1/private/companies/id/${companyId}/invitations/by_status`)
  }

  checkEmailInCompany(companyId: string, params: CheckEmailInCompanyRequest) {
    return this.identity.get(`/v1/private/companies/id/${companyId}/check_email`, { params })
  }

  acceptInvitation(companyId: Company['id'], inviteId: string, { set_as_active, ...body }: AcceptInvitationRequest) {
    return this.identity.post(`/v1/private/companies/id/${companyId}/invitation/id/${inviteId}/accept?set_as_active=` + set_as_active, body)
  }

  declineInvitation(companyId: Company['id'], inviteId: string) {
    return this.identity.post<Company>(`/v1/private/companies/id/${companyId}/invitation/id/${inviteId}/decline`)
  }

  getAllCompaniesExtended({ params, body }: GetAllPublicCompaniesExtended) {
    return this.identity.post<GetAllPublicCompaniesExtendedResponse>('/v1/private/companies/extended_list',
      body,
      {
        paramsSerializer: (params: any) => qs.stringify(params, { encode: false, arrayFormat: 'comma' }),
        params
      })
  }

  getAllCompaniesPublicExtended({ params, body = {} }: GetAllPublicCompaniesExtended) {
    return this.identity.post<GetAllPublicCompaniesExtendedResponse>('/v1/public/companies/extended_list',
      body,
      {
        paramsSerializer: (params: any) => qs.stringify(params, { encode: false, arrayFormat: 'comma' }),
        params
      })
  }

  publicGetCountriesByExtendedList(body: GetAllPublicCompaniesExtended["body"]) {
    return this.identity.post<Country[]>('/v1/public/companies/list/countries', body)
  }

  getAllCompanies({ params, body }: GetAllPublicCompanies) {
    return this.identity.post<GetAllPublicCompaniesResponse>('/v1/private/companies/list',
      body,
      {
        paramsSerializer: (params: any) => qs.stringify(params, { encode: false, arrayFormat: 'comma' }),
        params
      })
  }

  getAllCompaniesPublic({ params, body = {} }: GetAllPublicCompanies) {
    return this.identity.post<GetAllPublicCompaniesResponse>('/v1/public/companies/list',
      body,
      {
        paramsSerializer: (params: any) => qs.stringify(params, { encode: false, arrayFormat: 'comma' }),
        params
      })
  }

  // getCompanyByIdPublic(companyId: string) {
  //   return this.identity.get<Company>(`/v1/public/companies/id/${companyId}`)
  // }

  getCompanyByNicknamePublic(nickname: string) {
    return this.identity.get<Company>(`/v1/public/companies/nick/${nickname}`)
  }

  getCompanyByNickname(nickname: string) {
    return this.identity.get<Company>(`/v1/private/companies/nick/${nickname}`)
  }

  getOwnCompanies() {
    return this.identity.get<Company[]>('/v1/private/me/companies')
  }

  getCompanyById(companyId: string) {
    return this.identity.get<Company>(`/v1/private/companies/id/${companyId}`)
  }

  createCompany(body: CreateCompanyRequest) {
    return this.identity.post<Company>('/v1/private/companies', body)
  }

  updateCompany(company_id: string, body: UpdateCompanyRequest) {
    return this.identity.patch<Company>(`/v1/private/companies/id/${company_id}`, body)
  }

  deleteCompany(company_id: string, body: DeleteCompanyRequest) {
    return this.identity.delete(`/v1/private/companies/id/${company_id}`, {
      data: body
    })
  }

  addRepresentative(company_id: Company['id'], profile_id: Profile['id']) {
    return this.identity.post(`/v1/private/companies/id/${company_id}/representatives`, { profile_id })
  }

  deleteRepresentative(company_id: Company['id'], profile_id: Profile['id']) {
    return this.identity.delete(`/v1/private/companies/id/${company_id}/representatives/id/${profile_id}`)
  }

  getRepresentatives(company_id: Company['id']) {
    return this.identity.get<{ representatives: Representative[] }>(`/v1/private/companies/id/${company_id}/representatives/all`)
  }

  getPublicRepresentatives(company_id: Company['id']) {
    return this.identity.get<{ representatives: Representative[] }>(`/v1/public/companies/id/${company_id}/representatives/all`)
  }
}
