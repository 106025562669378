import stylesConfig from '@/assets/scss/config.module.scss'
import { ChatVirtualList } from '@/components/chats/ChatVirtualList/ChatVirtualList'
import { ChatGroups } from '@/components/chats/ui/ChatGroups/ChatGroups'
import { ChatSearchInput } from '@/components/chats/ui/ChatSearchInput/ChatSearchInput'
import { NoChatsPlaceholder } from '@/components/chats/ui/NoChatsPlaceholder/NoChatsPlaceholder'
import { NotificationsAlert } from '@/components/chats/ui/NotificationAlert/NotificationsAlert'
import { ChatContextMenu } from '@/components/chats/ui/ChatContextMenu/ChatContextMenu'
import { Invitations } from '@/components/companies/ui/Invitation/Invitation'
import { SidebarMenuItem } from '@/components/layouts/HomeLayout/Sidebar/SidebarMenuItem/SidebarMenuItem'
import { PrivateExchangeGuide } from '@/components/modals/PrivateExchangeGuide/PrivateExchangeGuide'
import { ChatItem, SearchChats } from '@/components/modals/SearchChats/SearchChats'
import { IncomingOffersIcon } from '@/components/privateExchange/shared/IncomingOffersIcon/IncomingOffersIcon'
import { Loadable } from '@/components/ui/Loadable/Loadable'
import { resolvePathByName, ROUTE_NAMES, useNavigateToModal } from '@/config/routes'
import { useProfileLink } from '@/global-modals/hooks/useProfileLink'
import { chatsService } from '@/store/chats/chats.service'
import { chatsStore } from '@/store/chats/chats.store'
import { clientSettings } from '@/store/client-settings/client-settings'
import { myCompaniesStore } from '@/store/companies/my_companies.store'
import { privateExchangeOffers } from '@/store/exchange/private-exchange.offers.store'
import { notificationsStore } from '@/store/notifications/notifications.store'
import { partnershipStore } from '@/store/partnership/partnership.store'
import { profilesStore } from '@/store/profiles/profiles.store'
import { uiStore } from '@/store/ui/ui.store'
import { ChatModel } from '@/types/models/chat'
import { Fade } from '@mui/material'
import { Info } from '@roolz/icons/Info'
import { Companies } from '@roolz/icons/sidebar/Companies'
import { DoubleArrowLeft } from '@roolz/icons/sidebar/DoubleArrowLeft'
import { MyOffers } from '@roolz/icons/sidebar/MyOffers'
import { PrivateExchange } from '@roolz/icons/sidebar/PrivateExchange'
import { PublicExchange } from '@roolz/icons/sidebar/PublicExchange'
import { Quotes } from '@roolz/icons/sidebar/Quotes'
import { toastError } from '@roolz/sdk/components/snackbars'
import { useLockScrolling } from '@roolz/sdk/hooks'
import { useBreakpoint } from '@roolz/sdk/hooks/useBreakpoint'
import { Chat, SpecialChatGroupsTypes } from '@roolz/types/api/chats'
import cn from 'classnames'
import { groupBy } from 'lodash-es'
import { observer } from 'mobx-react-lite'
import { forwardRef, useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import styles from './Sidebar.module.scss'
import { Volume } from '@roolz/icons/chats/Volume'
import { Silent } from '@roolz/icons/chats/Silent'

const Sidebar = observer(() => {
  const isDesktopVersion = useBreakpoint({
    minWidth: Number.parseFloat(stylesConfig.mobileSidebarBreakpointWidth)
  })

  const location = useLocation()
  const { lock, unlock } = useLockScrolling()

  useLayoutEffect(() => {
    uiStore.isSidebarMobileOpen ? lock() : unlock()

    return () => {
      unlock()
    }
  }, [uiStore.isSidebarMobileOpen])

  useEffect(() => {
    uiStore.isSidebarMobileOpen = false
    unlock()
  }, [location, isDesktopVersion])


  if(isDesktopVersion) {
    return <SidebarContent isDesktopVersion={isDesktopVersion}/>
  }

  return (
    <Fade
      in={uiStore.isSidebarMobileOpen}
      appear={false}
    >
      <SidebarContent isDesktopVersion={isDesktopVersion}/>
    </Fade>
  )
})

const Badge = ({ count }: { count: number }) => {
  const content = count > 9 ? '9+' : count.toString()
  return <div className={styles.amount}>{content}</div>
}

const SidebarContent = observer(forwardRef(({
  isDesktopVersion,
  className,
  ...rest
}: {
  isDesktopVersion: boolean
  className?: string
}, ref: any) => {
  const { t } = useTranslation('layout')

  return (
    <div
      ref={ref}
      className={cn(styles.sidebar, className, {
        [styles.sidebarCollapsed]: uiStore.isSidebarCollapsed
      })}
      {...rest}
    >
      <div className={styles.sidebar__content}>
        <div className={styles.sidebar__menu}>
          <SidebarMenuItem
            label={t('sidebar.nav.public_exchange')}
            prepend={<PublicExchange/>}
            to={resolvePathByName(ROUTE_NAMES.PUBLIC_EXCHANGE)}
          />

          <PrivateExchangeMenuItem/>

          <SidebarMenuItem
            label={t('sidebar.nav.my_offers')}
            prepend={<MyOffers/>}
            to={resolvePathByName(ROUTE_NAMES.MY_OFFERS)}
          />
          <SidebarMenuItem
            label={t('sidebar.nav.my_bids')}
            prepend={<Quotes/>}
            to={resolvePathByName(ROUTE_NAMES.MY_BIDS)}
          />
          <SidebarMenuItem
            label={t('sidebar.nav.companies')}
            prepend={<Companies/>}
            to={resolvePathByName(ROUTE_NAMES.COMPANIES)}
          />
        </div>

        <Invitations/>
      </div>

      <Chats isDesktopVersion={isDesktopVersion}/>

      <div className={styles.sidebar__footer}>
        <button
          className={styles.collapse}
          onClick={uiStore.toggleSidebarCollapse.bind(uiStore)}
        >
          <div className={styles.collapse__icon}>
            <DoubleArrowLeft/>
          </div>

          <span className={styles.collapse__label}>
            {t('sidebar.collapse')}
          </span>
        </button>
      </div>
    </div>
  )
}))

const PrivateExchangeMenuItem = observer(() => {
  const { t } = useTranslation('layout')

  const [guideOpen, setGuideOpen] = useState(false)
  const [isShowNewChip, setIsShowNewChip] = useState(
    () => !localStorage.getItem('isShowNewChip') && !partnershipStore.partnersList.length
  )

  const disablePrivateExchange = !profilesStore.my_profile?.active_space_company_id

  const hideNewChip = useCallback(() => {
    localStorage.setItem('isShowNewChip', 'true')
    setIsShowNewChip(false)
  }, [])

  useEffect(() => {
    if(partnershipStore.partnersList.length) {
      setIsShowNewChip(false)
    }
  }, [partnershipStore.partnersList.length])

  if(disablePrivateExchange) {
    return (<>
      <SidebarMenuItem
        label={t('sidebar.nav.private_exchange')}
        prepend={<PrivateExchange/>}
        append={<Info size={18} className={styles.privateExchangeInfoIcon}/>}
        onClick={() => setGuideOpen(true)}
      />

      <PrivateExchangeGuide
        open={guideOpen}
        onClose={() => setGuideOpen(false)}
      />
    </>)
  }

  return (
    <SidebarMenuItem
      to={resolvePathByName(ROUTE_NAMES.PRIVATE_EXCHANGE)}
      label={t('sidebar.nav.private_exchange')}
      prepend={<PrivateExchange/>}
      append={
        <div className={styles.badges}>
          {isShowNewChip && <div className={styles.new}>NEW</div>}
          {privateExchangeOffers.isNewIncomingOffers && <IncomingOffersIcon withBadge/>}
          {partnershipStore.incomingInvitesCount > 0 && <Badge count={partnershipStore.incomingInvitesCount}/>}
        </div>
      }
      onClick={hideNewChip}
    />
  )
})

const initialCtx: {
  chat: ChatModel | null,
  show: boolean,
  position: { left: number, top: number }
} = { chat: null, show: false, position: { left: 0, top: 0 } }

const Chats = observer(({
  isDesktopVersion
}: {
  isDesktopVersion: boolean
}) => {
  const { t } = useTranslation('chat/list')

  const [ctx, setCtx] = useState(initialCtx)
  const handleChatSelect = useCallback((chat: Chat) => {
    chatsService.setActiveChat(chat.id)
  }, [])

  const handleChatRightClick = useCallback((event: any, chat: ChatModel) => {
    setCtx({
      chat,
      show: true,
      position: {
        top: event.clientY,
        left: event.clientX
      }
    })
  }, [])

  const handleCtxClose = useCallback(() => {
    setCtx(initialCtx)
  }, [])

  const groups = useMemo(() => {
    const res = []

    const MAX_SYMBOLS_COUNT = 10

    const companyIdsWithChats = new Set()

    Object.values(chatsStore.sortedVisibleChats).forEach(chat => {
      companyIdsWithChats.add(chat.company_id)
    })

    const ids = Array.from(companyIdsWithChats)
    ids.sort()

    // TODO replace any
    res.push(
      ...ids
        .filter(companyId => myCompaniesStore.companyIds.includes(companyId))
        .map((companyId: any) => myCompaniesStore.find(companyId))
        .map((company: any) => ({
          name: company.name.length > MAX_SYMBOLS_COUNT
            ? company.name.substr(0, MAX_SYMBOLS_COUNT) + '..'
            : company.name,
          value: company.id
        }))
    )

    if(res.length) {
      res.unshift({ name: t('groups.all'), value: SpecialChatGroupsTypes.ALL })
      res.push({ name: t('groups.private'), value: SpecialChatGroupsTypes.PRIVATE })
    }

    return res
  }, [myCompaniesStore.companyIds, chatsStore.sortedVisibleChats])

  const chatsByGroups = useMemo(() => {
    const chatsWithinGroups = chatsStore.sortedVisibleChats.filter(chat => {
      return !!chat.company_id && myCompaniesStore.companyIds.find(id => id === chat.company_id)
    })

    return groupBy(chatsWithinGroups, chat => {
      return chat.company_id
    })
  }, [groups, chatsStore.sortedVisibleChats, myCompaniesStore.companyIds])

  const privateChats = useMemo(() => {
    return chatsStore.sortedVisibleChats.filter(chat => {
      return !chat.company_id || !myCompaniesStore.companyIds.find(id => id === chat.company_id)
    })
  }, [groups, chatsStore.sortedVisibleChats, myCompaniesStore.companyIds])

  const chatsOfActiveGroup = useMemo(() => {
    if(chatsStore.activeGroup === SpecialChatGroupsTypes.PRIVATE) return privateChats
    if(chatsStore.activeGroup === SpecialChatGroupsTypes.ALL) return chatsStore.sortedVisibleChats

    return chatsByGroups[chatsStore.activeGroup as any] ?? []
  }, [chatsStore.activeGroup, privateChats, chatsByGroups])

  useEffect(() => {
    if(Object.values(chatsOfActiveGroup).length === 0 && chatsStore.activeGroup !== SpecialChatGroupsTypes.ALL) {
      chatsStore.activeGroup = SpecialChatGroupsTypes.ALL
    }
  }, [chatsOfActiveGroup, chatsStore.activeGroup])

// TODO dont show CHAT GROUPS BEFORE LOADING CHAT DATA, IT JUMPS
  return (
    <div className={styles.chats}>
      <ChatsSearch/>

      {clientSettings.lastChatsLoadTime !== null ? (<>
        {groups.length > 2 && (
          <Fade in={!uiStore.isSidebarCollapsed} appear={false}>
            <ChatGroups
              value={chatsStore.activeGroup}
              groups={groups}
              onChange={value => chatsStore.activeGroup = value}
            />
          </Fade>
        )}

        {isDesktopVersion && (
          <NotificationsAlert/>
        )}

        <Fade in={true} appear={true}>
          {chatsOfActiveGroup.length ? (
            <ChatVirtualList
              className={styles.chats__content}
              chats={chatsOfActiveGroup}
              onChatSelect={handleChatSelect}
              onChatRightClick={handleChatRightClick}
            />
          ) : (
            <NoChatsPlaceholder/>
          )}
        </Fade>

        <ChatContextMenu
          {...ctx}
          chat={ctx.chat as ChatModel}

          onClose={handleCtxClose}
        />
      </>) : (
        <div className={styles.chats__placeholder}>
          <Loadable
            delay={1300}
            loading={true}
          />
        </div>
      )}
    </div>
  )
})


const ChatsSearch = observer(() => {
  const { t } = useTranslation('chat/common')
  const [open, setOpen] = useState<boolean>(false)
  const { openProfile } = useProfileLink()

  function handleOpenSearch() {
    setOpen(true)
  }

  function handleChatSelect(item: ChatItem) {
    if(item.type === 'profile') {
      if(chatsStore.getDialogWithUser(item.profile.id)) {
        chatsService.setActiveChatAsDialog(item.profile.id)
          .catch(() => {
            const { first_name, last_name } = item.profile.profile_view_info || {}

            toastError(t('errors.cant_create_dialog', {
              name: [first_name, last_name].join(' ')
            }))
          })
      } else {
        openProfile(item.profile.id)
      }
    } else if(item.type === 'chat') {
      chatsService.setActiveChatAsGroup(item.chat.id)
        .catch(response => {
          console.error(response)

          toastError(response?.data?.error_msg ?? t('errors:insufficient_request'))
        })
    }

    setOpen(false)
  }

  return (
    <div className={styles.chats__search}>
      <ChatSearchInput
        collapsed={uiStore.isSidebarCollapsed}
        onClick={handleOpenSearch}
      />

      {!uiStore.isSidebarCollapsed && (
        <button
          className={styles.soundTurn}
          onClick={() => notificationsStore.audioNotifications = !notificationsStore.audioNotifications}
        >
          {notificationsStore.audioNotifications
            ? <Volume color='#8E8E93' size={24}/>
            : <Silent color='#8E8E93' size={24} style={{ transform: 'scale(0.7)' }}/>
          }
        </button>
      )}

      <SearchChats
        title={t('search.title')}
        type='global'

        open={open}
        setOpen={setOpen}
        onChatSelect={handleChatSelect}
      />
    </div>
  )
})

export default Sidebar
