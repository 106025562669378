import { exchangeKnowledgeStore } from '@/store/knowledge/stores/exchange-knowledge.store'
import { getMaximalDate } from '@/utils/date'
import { numberOrUndefined } from '@/utils/types'
import { FilterInterface } from '@roolz/sdk/components/offers/ui/AllOffersFilter/AllOffersFilter'
import dayjs from '@roolz/sdk/plugins/dayjs'
import { OfferType } from '@roolz/types/api/exchange'
import { AdrClassSlug } from '@roolz/types/api/knowledge/exchange'
import { cloneDeep, isEqual, isEqualWith, isMatch } from 'lodash-es'
import { makeAutoObservable } from 'mobx'

export const DEFAULT_FILTER = (): FilterInterface => ({
  offer_type: OfferType.CARGO,

  start_point: { location: null, radius: null },
  end_point: { location: null, radius: null },
  loading_datetime: { date_from: null, date_to: null },

  transport_type: [],
  truck_bodies: [],

  adr: [],

  weight_min: null,
  weight_max: null,

  volume_min: null,
  volume_max: null
})

export class OffersFilterStore {
  autoupdate = true

  private _fields = DEFAULT_FILTER()

  constructor() {
    makeAutoObservable(this)
  }

  get fields() {
    return this._fields
  }

  set fields(filter: FilterInterface) {
    this._fields = filter
  }

  reset() {
    this._fields = DEFAULT_FILTER()
  }

  get isFilterTouched(): boolean {
    const filter: Partial<FilterInterface> = cloneDeep(this.fields)
    const defaultFilter: Partial<FilterInterface> = DEFAULT_FILTER()

    if(filter.offer_type === 'cargo') {
      delete filter.transport_type
      delete defaultFilter.transport_type
    }

    delete defaultFilter.offer_type
    delete filter.offer_type

    return !isEqual(filter, defaultFilter)
  }

  toggleAutoupdate = () => {
    this.autoupdate = !this.autoupdate
  }

  createParamsByFilter() {
    const filter = cloneDeep(this.fields)

    let dateFrom
    let dateTo
    if(filter.loading_datetime?.date_from) {
      dateFrom = dayjs(filter.loading_datetime?.date_from).utc().toISOString()

      if(filter.loading_datetime?.date_to) {
        dateTo = dayjs(getMaximalDate()).isSame(filter.loading_datetime?.date_to)
          ? null
          : dayjs(filter.loading_datetime?.date_to).utc().toISOString()
      } else {
        dateTo = dateFrom
      }
    }

    let pointFrom
    let pointTo
    if(filter.start_point?.location) {
      pointFrom = {
        osm_id: filter.start_point?.location?.osm_id,
        osm_type: filter.start_point.location.osm_type,
        radius: Number.parseFloat(filter.start_point.radius as string) * 1000 || null
      }
    }
    if(filter.end_point?.location) {
      pointTo = {
        osm_id: filter.end_point?.location?.osm_id,
        osm_type: filter.end_point.location.osm_type,
        radius: Number.parseFloat(filter.end_point.radius as string) * 1000 || null
      }
    }

    const weight_min = numberOrUndefined(Number.parseFloat('' + filter.weight_min))
    const weight_max = numberOrUndefined(Number.parseFloat('' + filter.weight_max))
    const volume_min = numberOrUndefined(Number.parseFloat('' + filter.volume_min))
    const volume_max = numberOrUndefined(Number.parseFloat('' + filter.volume_max))

    let adr
    if(filter.adr?.length) {
      adr = filter.adr
      if(filter.offer_type === OfferType.CARGO) {
        const dangerousSlugs = exchangeKnowledgeStore.supportedDangerousAdrClasses.map(item => item.slug)

        let someDangerousSelected = false

        adr.forEach((slug: AdrClassSlug) => {
          if(dangerousSlugs.includes(slug)) {
            someDangerousSelected = true
          }
        })

        if(someDangerousSelected) {
          adr = [
            ...adr.filter((slug: AdrClassSlug) => slug !== AdrClassSlug.any_dangerous),
            AdrClassSlug.any_dangerous
          ]
        }
      } else {
        adr = adr.filter((slug: AdrClassSlug) => slug !== AdrClassSlug.any_dangerous)
      }
    }

    return {
      offer_type: filter.offer_type,
      transport_type: filter.transport_type?.length ? filter.transport_type : undefined,
      transport_body: filter.truck_bodies?.length ? filter.truck_bodies : undefined,
      weight_min,
      weight_max,
      volume_min,
      volume_max,
      adr_class: adr,
      date_start: dateFrom,
      date_end: dateTo,
      start_point: pointFrom ? pointFrom : undefined,
      end_point: pointTo ? pointTo : undefined
    }
  }
}
